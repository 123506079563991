<script lang="ts">
	import './styles.css'
	import { refreshSessionCookie } from '$lib/firebase-client/auth'
	import { onMount } from 'svelte'
	import { Toaster } from '$lib/components/ui/sonner'
	import { user } from '$lib/stores/userStore'
	import { parsedToken } from '$lib/stores/parsedTokenStore'
	import { getClientAuth } from '$lib/firebase-client/app'
	import { onAuthStateChanged } from 'firebase/auth'
	import { captureException } from '@sentry/sveltekit'
	let isFirstVisit = true

	onMount(() => {
		const auth = getClientAuth()

		return onAuthStateChanged(auth, (authUser) => {
			user.set(authUser)
			authUser
				?.getIdTokenResult(true)
				.then((token) => {
					parsedToken.set(token.claims)
				})
				.catch((e) => {
					captureException(new Error('error while getServerData'), {
						originalException: e
					})
				})
			if (isFirstVisit) {
				if (authUser) {
					return refreshSessionCookie(authUser, true).catch((e) => {
						captureException(new Error('Failed to refresh session cookie'), {
							originalException: e
						})
					})
				}
			}
			isFirstVisit = false
		})
	})
</script>

<Toaster />

<slot />
